import { all, fork } from "redux-saga/effects";
import { watchLogin } from "./loginSaga";
import { watchForgotPassword } from "./forgotPasswordSaga";
import { watchResetPassword } from "./resetPasswordSaga";
import { watchCountry } from "./countrySaga";
import { watchDepartment } from "./departmentSaga";
import { watchCity } from "./citySaga";
import { watchRole } from "./roleSaga";
import { watchArea } from "./areaSaga";
import { watchZone } from "./zoneSaga";
import { watchNeighborhood } from "./neighborhoodSaga";
import { watchCompany } from "./companySaga";
import { watchPermission } from "./permissionSaga";
import { watchUser } from "./userSaga";
import { watchPermissionRole } from "./permissionRoleSaga";
import { watchAddress } from "./addressSaga";
import { watchRoute } from "./routeSaga";
import { watchObservation } from "./observationSaga";
import { watchZoneNeighborhood } from "./zoneNeighborhoodSaga";
import { watchRecord } from "./recordSaga";
import { watchAddressesEvent } from "./addressesEventSaga";
import { watchHeadquarters } from "./headquartersSaga";
import { watchRouteRecord } from "./routeRecordSaga";
import { watchReport } from "./reportSaga";
import { watchChangePassword } from "./changePasswordSaga";
import { watchLot } from "./lotSaga";
import { watchProduct } from "./productSaga";
import { watchOrder } from "./orderSaga";
import { watchCustomer } from "./customerSaga";
import { watchOrderDetail } from "./orderDetailSaga";
import { watchCategory } from "./categorySaga";
import { watchLotProduct } from "./lotProductSaga";
import { watchCollect } from "./collectSaga";
import { watchExternalMapping } from "./externalMappingSaga";
import { watchPdfTracking } from "./pdfTrackingSaga";
import { watchNote } from "./noteSaga";
import { watchAddresCardinalPoint } from "./addressCardinalSaga";
import { watchAddressInsideType } from "./addressInsideTypeSaga";
import { watchAllAddressNomenclature } from "./addressNomenclatureSaga";
import { watchAllAddressRoadTypeSaga } from "./addressRoadTypeSaga";
import { watchAddresCloseConfirmation } from "./AddressCloseConfirmationSaga";
import { watchStatus } from "./addressStatusSaga";
import { watchUnconfirmed } from "./financialSaga";
import { watchAuditoria } from "./auditoriaSaga";
import { watchBranch } from "./branchSaga";
import {watchFetchData} from "./fdxCartaPorteSaga.";
import { watchStatuses } from "./statusesSaga";
import { watchNovelty } from "./noveltySaga";
import { watchOrdersLocked } from "./OrdersLockedSaga";
import { watchAssignOperator } from "./assignOperatorSaga";
import { watchWarehouse } from "./warehouseSaga";
import { watchRescheduling } from "./reschedulingSaga";
import { watchInventory } from "./inventorySaga";
import { watchAuth } from "./authSaga";
import { watchCoverage } from "./coverageSaga";
import { watchOrigin } from "./originSaga";
import { watchAlerts } from "./alertsSaga";
import { watchAlertTypes } from "./alertTypeSaga";
import { watchAlertSources } from "./alertSourceSaga";
import { watchDSPOrder } from "./dspSaga";
import { watchDspAssignCourierOrder } from "./dspAssignCourierOrdersSaga";
import { watchWMS } from "./wmsSaga";
import { watchPreOrders } from "./preOrderSaga";

export function* rootSaga() {
  yield all([fork(watchLogin)]);
  yield all([fork(watchForgotPassword)]);
  yield all([fork(watchResetPassword)]);
  yield all([fork(watchCountry)]);
  yield all([fork(watchDepartment)]);
  yield all([fork(watchCity)]);
  yield all([fork(watchRole)]);
  yield all([fork(watchArea)]);
  yield all([fork(watchZone)]);
  yield all([fork(watchNeighborhood)]);
  yield all([fork(watchCompany)]);
  yield all([fork(watchPermission)]);
  yield all([fork(watchUser)]);
  yield all([fork(watchPermissionRole)]);
  yield all([fork(watchAddress)]);
  yield all([fork(watchRoute)]);
  yield all([fork(watchObservation)]);
  yield all([fork(watchZoneNeighborhood)]);
  yield all([fork(watchRecord)]);
  yield all([fork(watchAddressesEvent)]);
  yield all([fork(watchHeadquarters)]);
  yield all([fork(watchRouteRecord)]);
  yield all([fork(watchReport)]);
  yield all([fork(watchChangePassword)]);
  yield all([fork(watchLot)]);
  yield all([fork(watchProduct)]);
  yield all([fork(watchOrder)]);
  yield all([fork(watchCustomer)]);
  yield all([fork(watchCategory)]);
  yield all([fork(watchOrderDetail)]);
  yield all([fork(watchLotProduct)]);
  yield all([fork(watchCollect)]);
  yield all([fork(watchExternalMapping)]);
  yield all([fork(watchPdfTracking)]);
  yield all([fork(watchNote)]);
  yield all([fork(watchAddresCardinalPoint)]);
  yield all([fork(watchAddressInsideType)]);
  yield all([fork(watchAllAddressNomenclature)]);
  yield all([fork(watchAllAddressRoadTypeSaga)]);
  yield all([fork(watchAddresCloseConfirmation)]);
  yield all([fork(watchStatus)]);
  yield all([fork(watchUnconfirmed)]);
  yield all([fork(watchAuditoria)]);
  yield all([fork(watchBranch)]);
  yield all([fork(watchFetchData)]);
  yield all(([fork(watchStatuses)]));
  yield all(([fork(watchNovelty)]));
  yield all([fork(watchOrdersLocked)]);
  yield all([fork(watchAssignOperator)]);
  yield all([fork(watchWarehouse)]);
  yield all([fork(watchRescheduling)]);
  yield all([fork(watchInventory)]);
  yield all([fork(watchAuth)]);
  yield all([fork(watchCoverage)]);
  yield all([fork(watchOrigin)]);
  yield all([fork(watchAlerts)]);
  yield all([fork(watchAlertTypes)]);
  yield all([fork(watchAlertSources)]);
  yield all([fork(watchDSPOrder)]);
  yield all([fork(watchDspAssignCourierOrder)]);
  yield all([fork(watchWMS)]);
  yield all([fork(watchPreOrders)]);
}
